import React, { createContext, useState, useEffect } from "react";
import { BASE_URL } from "../App";

// Create the context
export const UserContext = createContext({
  user: null,
  loading: true,
});

// Create a provider component
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        // const response = await fetch(`${BASE_URL}/auth/`);
        // const text = await response.text();
        // const data = JSON.parse(text);

        // if (data.ok) {
        //   if (data.user.length === 0) {
        //     setUser(null);
        //   } else {
        //     setUser(data.user);
        //   }
        // }

        const newUser = {
          id: 11,
          id_office: null,
          time_created: null,
          time_updated: null,
          name: "Noah Melamed",
          role: null,
          avatar:
            "https://lh3.googleusercontent.com/a/ACg8ocJ3mzRVnd5ssgkQWd5vVVTrhUHE77Lsh4j4XHfYzPwE-IOAmQ=s96-c",
          phone: null,
          email: "noah.melamed@1000heads.com",
        };

        setUser(newUser);
      } catch (error) {
        console.error("Error checking authentication:", error);
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, loading }}>
      {children}
    </UserContext.Provider>
  );
};
